<template>
  <section class="systems">
    <div class="container">
      <div class="systems__title major-title">
        {{ $t('message.systems.title') }}
      </div>
    </div>
    <Products-filter
      :products="allSystems"
      :categories="systemsCategories"
      :normalizedCategories="normalizedCategories"
      @returnFilteredProducts="showFilteredProducts"
    />
    <div class="container">
      <Products-view
        :products="productsToProductView"
        placement="system"
        @productSelected="updateSectorSystem"
        @createCustom="createCustomSystem"
      >
        <template #image>
          <img src="@/assets/img/icons/product--create.svg" alt="create" />
        </template>
        <i18n path="message.systems.create" class="create-btn__text">
          <template #break>
            <br />
          </template>
        </i18n>
      </Products-view>
    </div>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </section>
</template>

<script>
import ProductsView from '@/components/smart/products/Products-view'
import ProductsFilter from '@/components/smart/products/Products-filter'
import { mapMutations, mapState, mapActions } from 'vuex'
import { getItem } from '@/api'
import { stepNumbers } from '@/utils/stepNumbers'

export default {
  data: () => ({
    filteredProducts: [],
    isLoading: false,
    isFiltered: false
  }),
  components: {
    ProductsView,
    ProductsFilter
  },
  methods: {
    ...mapActions({
      updateStep: 'updateStep'
    }),
    ...mapMutations({
      UPDATE_SECTOR_SYSTEM: 'UPDATE_SECTOR_SYSTEM',
      CREATE_CUSTOM_SYSTEM: 'CREATE_CUSTOM_SYSTEM'
    }),
    updateSectorSystem(id) {
      this.isLoading = true
      getItem(this.$i18n.locale, 'system', id).then(response => {
        // console.log(response);
        this.UPDATE_SECTOR_SYSTEM({
          index: this.sectorIndex,
          systemId: id,
          data: response.data
          // data: Object.prototype.hasOwnProperty.call(response.data, 'slope') ? { layers: this.responseLayers, slope: response.data.slope} :  { layers: this.responseLayers }
        })
        this.isLoading = false
        this.$router.push(`/${this.$i18n.locale}/system/${this.sectorIndex}`)
      })
    },
    createCustomSystem() {
      this.CREATE_CUSTOM_SYSTEM(this.sectorIndex)
      this.$router.push(`/${this.$i18n.locale}/system/${this.sectorIndex}`)
    },
    showFilteredProducts(products) {
      this.isFiltered = true
      this.filteredProducts = products
    }
  },
  mounted() {
    if (!this.allSystems.length) {
      this.$router.push(`/${this.$i18n.locale}`)
    }
    this.updateStep(stepNumbers.systems)
  },
  computed: {
    ...mapState({
      allSystems: state => state.systems,
      systemsCategories: state => state.systemsCategories,
      responseLayers: state => state.layers
    }),
    normalizedCategories() {
      return Object.fromEntries(this.systemsCategories.map(c => [c.id, []]))
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    productsToProductView() {
      return this.isFiltered ? this.filteredProducts : this.allSystems
      // return this.filteredProducts.length === 0 ? this.allSystems : this.filteredProducts;
    }
  },
  name: 'Systems'
}
</script>

<style scoped lang="sass"></style>
